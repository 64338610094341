<template>
  <div class="banner-container">
    <div class="facebook-overlay" @click="openFacebook"></div>
    <div class="instagram-overlay" @click="openInstagram"></div>
  </div>
</template>

<script>
export default {
  name: 'VartaContact',
  methods: {
    openFacebook() {
      window.open('https://www.facebook.com/electro3.sj', '_blank');
    },
    openInstagram() {
      window.open('https://www.instagram.com/electro3.sj/', '_blank');
    }
  }
};
</script>

<style scoped>
.banner-container {
  margin: 1.5rem auto;
  width: 90%;
  height: 40rem; /* Ajusta la altura según tus necesidades */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: url('@/assets/Varta/Contact.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; /* Para permitir posicionamiento absoluto dentro del contenedor */
}

/* Crea un div invisible que cubra el área del icono de instagram */
.instagram-overlay {
  background-color: transparent; /* Lo hacemos invisible */
  position: absolute; /* Posicionamiento absoluto dentro del contenedor */
  right: 19%; /* Ajusta según la posición del icono de instagram */
  bottom: 43%; /* Ajusta según la posición del icono de instagram */
  width: 10%; /* Ajusta al tamaño del icono de instagram */
  height: 5%; /* Ajusta la altura */
  cursor: pointer;
  z-index: 2;
}
/* Crea un div invisible que cubra el área del icono de Facebook */
.facebook-overlay {
  background-color: transparent; /* Lo hacemos invisible */
  position: absolute; /* Posicionamiento absoluto dentro del contenedor */
  right: 19%; /* Ajusta según la posición del icono de Facebook */
  bottom: 35%; /* Ajusta según la posición del icono de Facebook */
  width: 10%; /* Ajusta al tamaño del icono de Facebook */
  height: 5%; /* Ajusta la altura */
  cursor: pointer;
  z-index: 2;
}

@media (max-width: 1600px) {
  .instagram-overlay {
    right: 14%; /* Ajusta según la posición del icono de instagram */
    bottom: 44%; /* Ajusta según la posición del icono de instagram */
    width: 15%; /* Ajusta al tamaño del icono de instagram */
  }
/* Crea un div invisible que cubra el área del icono de Facebook */
  .facebook-overlay {
    right: 14%; /* Ajusta según la posición del icono de Facebook */
    bottom: 37%; /* Ajusta según la posición del icono de Facebook */
    width: 15%; /* Ajusta al tamaño del icono de instagram */
  }  
}

@media (max-width: 1250px) {
  .instagram-overlay {
    height: 4%; /* Ajusta la altura */
    bottom: 45%; /* Ajusta según la posición del icono de instagram */
  }
/* Crea un div invisible que cubra el área del icono de Facebook */
  .facebook-overlay {
    height: 4%; /* Ajusta la altura */
    bottom: 40%; /* Ajusta según la posición del icono de Facebook */
  }  
}

/* Estilos responsivos para pantallas pequeñas */
@media (max-width: 1000px) {
  .banner-container {
    width: 100%;
    height: 40rem;
    background-image: url('@/assets/Varta/Contact - movil.png');
    background-size: cover;
  }
  .instagram-overlay {
    left: 14%; /* Ajusta según la posición del icono de instagram */
    bottom: 39%; /* Ajusta según la posición del icono de instagram */
    width: 27%; /* Ajusta al tamaño del icono de instagram */
    height: 8%; /* Ajusta la altura */
  }
/* Crea un div invisible que cubra el área del icono de Facebook */
  .facebook-overlay {
    left: 14%; /* Ajusta según la posición del icono de Facebook */
    bottom: 27%; /* Ajusta según la posición del icono de Facebook */
    width: 27%; /* Ajusta al tamaño del icono de Facebook */
    height: 8%; /* Ajusta la altura */
  }
}

@media (max-width: 768px) {
  .banner-container {
    height: 20rem;
    background-image: url('@/assets/Varta/Contact - movil.png');
    background-size: cover;
  }
  .instagram-overlay {
    left: 10%; /* Ajusta según la posición del icono de instagram */
    bottom: 39%; /* Ajusta según la posición del icono de instagram */
    width: 30%; /* Ajusta al tamaño del icono de instagram */
  }
/* Crea un div invisible que cubra el área del icono de Facebook */
  .facebook-overlay {
    left: 10%; /* Ajusta según la posición del icono de Facebook */
    bottom: 27%; /* Ajusta según la posición del icono de Facebook */
    width: 30%; /* Ajusta al tamaño del icono de Facebook */
  }   
}
</style>
