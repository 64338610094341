import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Importar PrimeVue y sus estilos
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura'; //theme
import 'primeicons/primeicons.css';  // Importar íconos
import 'primeflex/primeflex.css';
import '@fortawesome/fontawesome-free/css/all.css';


import Button from 'primevue/button';


// Crear la aplicación e integrar PrimeVue, router y store
const app = createApp(App);

app.use(store);
app.use(router);
app.use(PrimeVue, {
    theme: {
        preset: Aura
    }
})

app.mount('#app');

app.component('PrimeButton', Button);


