<template>
  <div class="varta-container">
    <!-- Menú -->
    <VartaMenu />
    <!-- <VartaCarousel /> -->
    <div v-if="isParentRoute">
      <!-- Imagen para poner texto-->
      <VartaPresentation />
      <!-- Sección de características -->
      <!-- <VartaFeatures /> -->
    </div>
    
    <router-view></router-view>
    <!-- <VartaFooter/>  -->
    <!-- Varta Silver -->
</div>

</template>

<script>
import VartaMenu from '@/components/Varta/VartaMenu.vue'; // Importa el subcomponente
import VartaPresentation from '@/components/Varta/VartaPresentation.vue';
// import VartaFeatures from '@/components/Varta/VartaFeatures.vue';
// import VartaFooter from '@/components/Varta/VartaFooter.vue';
// import VartaCarousel from '@/components/Varta/VartaCarousel.vue';
// import VartaSilver from '@/components/Varta/VartaSilverOld.vue';

export default {
  components: {
    VartaMenu, VartaPresentation
  },
  data() {
    return {
    };
  },
  computed: {
    isParentRoute() {
      const route = this.$route;
      // Verifica si estás en la ruta '/varta' y no en sus hijas
      return route.path === '/varta/' || route.path === '/varta';// se hizo asi por que en mozilla muestra la primera opcion y en crhom la segunda
    }
  }
};
</script>

<style scoped>
</style>
