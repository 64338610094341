<template class="banner">
  <div class="banner-container">
  </div>
</template>

<script>
export default {
  name: 'VartaMineria',
};
</script>

<style scoped>
.banner {
  justify-content: center;
  align-items: center;
}

.banner-container {
  margin: 3rem auto;
  width: 100%;
  height: 40rem; /* Ajusta la altura según tus necesidades */
  display: flex;
  justify-content: flex-start; /* Alinea el contenido hacia la izquierda */
  align-items: center;
  background-image: url('@/assets/Varta/Mineria.png');
  background-size: contain; /* La imagen se ajustará al contenedor sin recortarse */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

@media (max-width: 1000px) {
  .banner-container {
      width: 100%; /* Ocupa el 100% del ancho en pantallas pequeñas */
      height: 30rem; /* Reduce la altura del banner en móviles */
      padding-left: 1rem; /* Reduce el padding en pantallas pequeñas */
      justify-content: center; /* Centra el contenido en pantallas móviles */
      background-image: url('@/assets/Varta/Mineria - movil.png');
      background-position: right; /* Muestra solo la parte derecha de la imagen */
      background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
      position: relative; /* Necesario para la capa oscura */
    }
}
@media (max-width: 768px) {
  .banner-container {
      width: 100%; /* Ocupa el 100% del ancho en pantallas pequeñas */
      height: 15rem; /* Reduce la altura del banner en móviles */
      padding-left: 1rem; /* Reduce el padding en pantallas pequeñas */
      justify-content: center; /* Centra el contenido en pantallas móviles */
      background-image: url('@/assets/Varta/Mineria - movil.png');
      background-position: right; /* Muestra solo la parte derecha de la imagen */
      background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
      position: relative; /* Necesario para la capa oscura */
    }
}

</style>
