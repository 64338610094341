import { createRouter, createWebHashHistory } from 'vue-router';
import Electro3Component from '../components/Electro3Component.vue';
import SobreNosotros from '../components/SobreNosotros.vue';
import VartaComponent from '../components/VartaComponent.vue';
import VartaSilver from '../components/Varta/VartaSilver.vue';
import VartaBlue from '../components/Varta/VartaBlue.vue';
import VartaBlack from '../components/Varta/VartaBlack.vue';
import VartaMineria from '../components/Varta/VartaMineria.vue';
import VartaContact from '../components/Varta/VartaContact.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Electro3Component,
    children: [
      {
        path: 'SobreNosotros',  // Esta ruta será accesible como /SobreNosotros
        name: 'SobreNosotros',
        component: SobreNosotros
      },]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/varta',
    name: 'VartaComponent',
    component: VartaComponent,
    children: [
      {
        path: 'silver',  // Esta ruta será accesible como /varta/silver
        name: 'VartaSilver',
        component: VartaSilver
      },
      {
        path: 'Blue',  // Esta ruta será accesible como /varta/Blue
        name: 'VartaBlue',
        component: VartaBlue
      },
      {
        path: 'Black',  // Esta ruta será accesible como /varta/Black
        name: 'VartaBlack',
        component: VartaBlack
      },
      {
        path: 'Mineria',  // Esta ruta será accesible como /varta/Mineria
        name: 'VartaMineria',
        component: VartaMineria
      },
      {
        path: 'Contact',  // Esta ruta será accesible como /varta/Contact
        name: 'VartaContact',
        component: VartaContact
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// Redireccionamiento basado en el dominio
router.beforeEach((to, from, next) => {
  const domain = window.location.hostname;
  
  if (domain === 'varta.com.ar' && to.path === '/') {
    next({ path: '/varta' });  // Redirige a /varta sólo si estás en la raíz
  } else {
    next();  // Permite la navegación normal para todas las otras rutas
  }
});


export default router;
